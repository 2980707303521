import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import format from "date-fns/format";
import addMinutes from "date-fns/addMinutes";
import { MainLayout } from "../../components/main-layout";
import { ReservationSummary } from "../../contracts/reservation-contacts-contracts";
import {
  getHumanReadableTimeTitle,
  getMinutesFromLength,
  getPriceString,
} from "../reservation/ReservationHelpers";
import { CURRENCY } from "../../constant";
import { ANY_WORKER_ID } from "../reservation/ReservationContracts";
import { Employee } from "../../api/Organization";
import { VisitBookingResponse } from "../../api/Visit";
import s from "./ReservationCompletePage.module.scss";

const isValidReservationSummary = (
  state: unknown
): state is ReservationSummary => {
  return (
    typeof state === "object" &&
    state !== null &&
    "stepOneData" in state &&
    "stepTwoData" in state
  );
};

const resolveEmployeeDisplayName = (
  employee: Employee,
  anyEmployeeSummary: VisitBookingResponse,
  visitNumber: number
): string => {
  if (employee.id !== ANY_WORKER_ID) {
    return employee.displayName;
  }

  return (
    anyEmployeeSummary[
      (`service${visitNumber}WorkerName` as "service1VisitId") ||
        "service2VisitId"
    ] || "Bet kuris specialistas"
  );
};

export const ReservationCompletePage = () => {
  const { state } = useLocation();

  if (!isValidReservationSummary(state)) {
    return (
      <MainLayout contentClassName={s.content}>
        <h1>Susisiekime</h1>
        <p>Jūsų rezervacija užregistruota. Apie apsilankymą Jums priminsime!</p>
        <p>Iki susitikimo!</p>
        <Link className={classNames("button", s.button)} to="/">
          Į pradžią
        </Link>
      </MainLayout>
    );
  }

  const startDate = new Date(state.stepOneData.selectedDate);
  const procedureLengthInMinutes =
    state.stepOneData.detailedSelectedProcedures.reduce(
      (minutes, procedure) => {
        return minutes + getMinutesFromLength(procedure.service.length);
      },
      0
    );
  const endDate = addMinutes(
    new Date(state.stepOneData.selectedDate),
    procedureLengthInMinutes
  );
  const humanReadableLength = getHumanReadableTimeTitle(
    procedureLengthInMinutes
  );

  return (
    <MainLayout contentClassName={s.content}>
      <h1>Pasimatysime</h1>
      <p>
        Jūsų rezervacija užregistruota. Lauksime Jūsų{" "}
        <strong>{`${format(startDate, "yyyy-MM-dd HH:mm")} - ${format(
          endDate,
          "HH:mm"
        )} (trukmė ${humanReadableLength})`}</strong>
        .
      </p>
      <p>Apie apsilankymą Jums priminsime, iki susitikimo!</p>
      <ul>
        <li>
          <header>Jūsų kontaktai</header>
          <ul>
            <li>{state.stepTwoData.name}</li>
            <li>{state.stepTwoData.phone}</li>
          </ul>
        </li>
        <li>
          <header>Jūsų laukia</header>
          {state.stepOneData.detailedSelectedProcedures.map(
            ({ service, employee, price }, index, self) => {
              const lengthUntilProcedureMinutes = self
                .slice(0, index)
                .reduce((minutes, { service }) => {
                  return minutes + getMinutesFromLength(service.length);
                }, 0);
              const start = addMinutes(
                new Date(state.stepOneData.selectedDate),
                lengthUntilProcedureMinutes
              );
              const end = addMinutes(
                new Date(start),
                getMinutesFromLength(service.length)
              );

              return (
                <section key={service.id} className={s.procedure}>
                  <header>{`Procedūra #${index + 1}`}</header>
                  <ul>
                    <li>{service.name}</li>
                    <li>
                      {" "}
                      {`${format(start, "HH:mm")} - ${format(
                        end,
                        "HH:mm"
                      )} (${getHumanReadableTimeTitle(service.length)})`}
                    </li>
                    <li>{`${resolveEmployeeDisplayName(
                      employee,
                      state.anyEmployeeSummary,
                      index + 1
                    )} (${`${getPriceString(price)} ${CURRENCY}`})`}</li>
                  </ul>
                </section>
              );
            }
          )}
        </li>
      </ul>
      <Link className={classNames("button", s.button)} to="/">
        Į pradžią
      </Link>
    </MainLayout>
  );
};
