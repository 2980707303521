import { useState } from "react";
import classNames from "classnames";
import {
  SelectedProcedure,
  ServicesByCategory,
  WorkersById,
} from "../../pages/reservation/ReservationContracts";
import { ServiceEmployee } from "../../components/service-employee/ServiceEmployee";
import {
  getHumanReadableTimeTitle,
  getPriceString,
} from "../../pages/reservation/ReservationHelpers";
import { pluralizeLithuanianString } from "../../utils/plurals";
import { Dropdown } from "../dropdown";
import { Category } from "../../api/Organization";
import s from "./ProcedurePicker.module.scss";

interface Props {
  servicesByCategory: ServicesByCategory;
  categoryList: Category[];
  workersById: WorkersById;
  onProcedureSelected: (selectedProcedure: SelectedProcedure) => void;
  selectedProcedureIds: string[];
}

export const ProcedurePicker = ({
  servicesByCategory,
  categoryList,
  workersById,
  selectedProcedureIds,
  onProcedureSelected,
}: Props) => {
  const [activeCategoryId, setActiveCategoryId] = useState<
    string | undefined
  >();
  const filteredServicesByCategory = Object.entries(
    servicesByCategory
  ).reduce<ServicesByCategory>(
    (servicesByCategory, [category, servicesOfCategory]) => {
      const filteredServicesOfCategory = servicesOfCategory.filter(
        (service) => {
          return !selectedProcedureIds.includes(service.id);
        }
      );

      if (!filteredServicesOfCategory.length) {
        return servicesByCategory;
      }

      servicesByCategory[category] = filteredServicesOfCategory;

      return servicesByCategory;
    },
    {}
  );

  return (
    <div className={s.procedurePicker}>
      {categoryList.map((category) => {
        if (!filteredServicesByCategory[category.id]?.length) {
          return null;
        }

        const isCategoryActive = activeCategoryId === category.id;

        return (
          <article className={s.reservationCategoryContainer} key={category.id}>
            <header
              className={s.reservationCategoryHeader}
              onClickCapture={(event) => {
                if (category.id === activeCategoryId) {
                  setActiveCategoryId(undefined);
                } else {
                  setActiveCategoryId(category.id);
                  const element = event.currentTarget;
                  setTimeout(() => {
                    element.scrollIntoView({ behavior: "smooth" });
                  }, 100);
                }
              }}
            >
              <span>{category.name}</span>
              <svg
                version="1.2"
                baseProfile="tiny-ps"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 557 540"
                width="16"
                height="16"
                className={classNames(s.expand, {
                  [s.categoryOpen]: isCategoryActive,
                })}
              >
                <path
                  id="Layer"
                  d="M556.9 261.1C556.9 265.97 555.03 270.27 551.3 274L291.3 534C287.57 537.73 283.27 539.6 278.4 539.6C273.53 539.6 269.27 537.73 265.6 534L5.5 274C1.83 270.27 0 265.97 0 261.1C0 256.23 1.83 251.97 5.5 248.3L33.5 220.4C37.17 216.67 41.43 214.8 46.3 214.8C51.17 214.8 55.43 216.67 59.1 220.4L278.4 439.7L497.7 220.4C501.43 216.67 505.73 214.8 510.6 214.8C515.47 214.8 519.73 216.67 523.4 220.4L551.3 248.3C555.03 252.03 556.9 256.3 556.9 261.1L556.9 261.1ZM556.9 46.9C556.9 51.7 555.03 55.97 551.3 59.7L291.3 319.7C287.57 323.43 283.27 325.3 278.4 325.3C273.53 325.3 269.27 323.43 265.6 319.7L5.5 59.7C1.83 55.97 0 51.7 0 46.9C0 42.1 1.83 37.8 5.5 34L33.5 6.1C37.17 2.37 41.43 0.5 46.3 0.5C51.17 0.5 55.43 2.37 59.1 6.1L278.4 225.4L497.7 6.1C501.43 2.37 505.73 0.5 510.6 0.5C515.47 0.5 519.73 2.37 523.4 6.1L551.3 34C555.03 37.73 556.9 42.03 556.9 46.9L556.9 46.9Z"
                />
              </svg>
            </header>
            {isCategoryActive
              ? filteredServicesByCategory[category.id].map(
                  ({ name, length, workerIdList, id }) => {
                    const workerCount =
                      workerIdList.length > 1
                        ? workerIdList.length - 1
                        : workerIdList.length;
                    return (
                      <article className={s.procedure} key={`${name}-${id}`}>
                        <header>
                          <span>{name}</span>
                          <span className={s.length}>
                            Trukmė: {getHumanReadableTimeTitle(length)}
                          </span>
                        </header>
                        <Dropdown
                          className={s.workerPicker}
                          header={(isOpen, onClick) => {
                            return (
                              <header onClick={onClick}>
                                <div className={s.workersSummary}>
                                  {workerCount}{" "}
                                  {pluralizeLithuanianString(
                                    workerCount,
                                    "specialistas"
                                  )}
                                </div>
                                <div className={s.workerPickerSideContainer}>
                                  <span className={s.workerPickerSeparator} />
                                  <div
                                    className={s.iconContainer}
                                    aria-hidden="true"
                                  >
                                    <span className={s.iconTitle}>
                                      {isOpen ? "slėpti" : "rinktis"}
                                    </span>
                                    <svg
                                      height="20"
                                      width="20"
                                      viewBox="0 0 20 20"
                                      aria-hidden="true"
                                      focusable="false"
                                      className={classNames(s.icon, {
                                        [s.reverse]: isOpen,
                                      })}
                                    >
                                      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
                                    </svg>
                                  </div>
                                </div>
                              </header>
                            );
                          }}
                        >
                          <article className={s.workerList}>
                            {workerIdList.map(({ workerId, price }) => {
                              const { displayName, description } =
                                workersById[workerId];
                              return (
                                <ServiceEmployee
                                  className={s.serviceEmployee}
                                  key={`worker-${workerId}`}
                                  displayName={displayName}
                                  description={description}
                                  price={getPriceString(price)}
                                  workerId={workerId}
                                  onClick={() => {
                                    onProcedureSelected({
                                      id,
                                      categoryId: category.id,
                                      workerId,
                                    });
                                  }}
                                />
                              );
                            })}
                          </article>
                        </Dropdown>
                      </article>
                    );
                  }
                )
              : null}
          </article>
        );
      })}
    </div>
  );
};
