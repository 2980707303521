import { API_BASE_URL, ORGANIZATION_ID } from "./api-config";

interface TimeRange {
  start: string;
  end: string;
}

export interface Employee {
  id: string;
  displayName: string;
  description: string | null;
}

export interface ServiceEmployee {
  workerId: string;
  price: number;
}

export interface Category {
  id: string;
  name: string;
}

export interface Service {
  id: string;
  name: string;
  categoryId: string;
  length: string;
  workerIdList: ServiceEmployee[];
}

export interface OrganizationInfo {
  id: string;
  name: string;
  workTime: Record<string, TimeRange | null>;
  workerList: Employee[];
  serviceList: Service[];
  categoryList: Category[];
}

const BASE_URL = `${API_BASE_URL}/api/Organization`;

interface Options {
  signal?: AbortSignal;
}

export const Organization = {
  OrganizationInfo: async (
    requestOptions: Options
  ): Promise<OrganizationInfo> => {
    return (
      await fetch(`${BASE_URL}/OrganizationInfo/${ORGANIZATION_ID}`, {
        method: "GET",
        ...requestOptions,
      })
    ).json();
  },
};
