export function pluralizeLithuanianString(count: number, word: string): string {
  // Define the base, plural, and genitive forms of the word
  const base: string = word.replace(/as$/, "");
  const plural: string = base + "ai";
  const genitive: string = base + "ų";

  // Check if the count is 1 or ends in 1, except for numbers ending in 11
  if (count === 1 || (count % 10 === 1 && count % 100 !== 11)) {
    return word;
  }

  // Check if the count ends in 0, or ends in 10-20
  if (
    count === 0 ||
    count % 10 === 0 ||
    (count % 100 >= 10 && count % 100 <= 20)
  ) {
    return genitive;
  }

  // If none of the above conditions are met, return the plural form
  return plural;
}
