import { useEffect, useState } from "react";
import classNames from "classnames";
import s from "./async-image.module.scss";

enum Status {
  Pending = "pending",
  Loaded = "loaded",
  Error = "error",
}

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    "src"
  > {
  src: string;
  fallback: (
    status: Status.Pending | Status.Error,
    props: React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >
  ) => JSX.Element;
}

export const AsyncImage = ({ fallback, src, ...restProps }: Props) => {
  const [status, setStatus] = useState(Status.Pending);
  useEffect(() => {
    const image = new Image();
    const loadHandler = () => {
      setStatus(Status.Loaded);
    };
    const errorHandler = () => {
      setStatus(Status.Error);
    };
    image.addEventListener("load", loadHandler);
    image.addEventListener("error", errorHandler);

    image.src = src;
    return () => {
      image.removeEventListener("load", loadHandler);
      image.addEventListener("error", errorHandler);
    };
  }, [src]);

  const imgClassName = classNames(restProps.className, s.img);
  return status !== Status.Loaded ? (
    fallback(status, restProps)
  ) : (
    <img src={src} {...restProps} className={imgClassName} />
  );
};
