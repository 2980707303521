import { Employee, Service, ServiceEmployee } from "../../api/Organization";

export const ANY_WORKER_ID = "any-worker";

export type EmployeePrice =
  | {
      min: number;
      max: number;
    }
  | number;

export interface AnyEmployee {
  workerId: typeof ANY_WORKER_ID;
  price: EmployeePrice;
}

export interface ModifiedService extends Omit<Service, "workerIdList"> {
  workerIdList: Array<ServiceEmployee | AnyEmployee>;
  id: string;
}

export type ServicesByCategory = Record<string, ModifiedService[]>;

export type WorkersById = Record<string, Employee>;

export interface SelectedProcedure {
  id: string;
  workerId: string;
  categoryId: string;
}
