import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import ReactDOM from "react-dom";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "./reset.css";
import "./index.scss";

import { ReservationPage } from "./pages/reservation/ReservationPage";
import { ReservationContactsPage } from "./pages/reservation-contacts/ReservationContactsPage";
import { ReservationCompletePage } from "./pages/reservation-complete/ReservationCompletePage";

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path="/rezervacija" element={<ReservationPage />} />
            <Route path="/rezervacija/kontaktai" element={<ReservationContactsPage />} />
            <Route path="/rezervacija/sekminga" element={<ReservationCompletePage />} />
            <Route path="*" element={<Navigate to="/rezervacija" replace />} />
        </Routes>
    </BrowserRouter>,
    document.getElementById("root")
);
