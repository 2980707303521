import { useLayoutEffect, useMemo } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import format from "date-fns/format";
import addMinutes from "date-fns/addMinutes";
import classNames from "classnames";
import { MainLayout } from "../../components/main-layout";
import {
  ANY_WORKER_ID,
  SelectedProcedure,
} from "../reservation/ReservationContracts";
import { HTTPError, Visit } from "../../api/Visit";
import {
  ReservationStepOneData,
  ContactsFormPage as ContactsFormSummary,
  RESERVATION_STEP_ONE_DATA_KEY,
  RESERVATION_STEP_TWO_DATA_KEY,
  RESERVATION_STEP_ZERO_DATA_KEY,
  ReservationSummary,
} from "../../contracts/reservation-contacts-contracts";
import {
  getHumanReadableTimeTitle,
  getMinutesFromLength,
  getPriceString,
} from "../reservation/ReservationHelpers";
import { CURRENCY } from "../../constant";
import { SelectionMode } from "../reservation/ReservationPage";
import s from "./ReservationContactsPage.module.scss";

enum FormEntries {
  Name = "name",
  Phone = "phone",
  RemindViaSMS = "remindViaSMS",
  AgreedWithConsent = "agreedWithConsent",
}

const formDataToString = (formData: FormData): ContactsFormSummary => {
  return {
    name: (formData.get(FormEntries.Name) as string) || "",
    phone: (formData.get(FormEntries.Phone) as string) || "",
    remindViaSMS: (formData.get(FormEntries.RemindViaSMS) as string) || "",
    agreedWithConsent:
      (formData.get(FormEntries.AgreedWithConsent) as string) || "",
  };
};

type State = ReservationStepOneData;

// TODO: Route leave hook.
export const ReservationContactsPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const state = useMemo(() => {
    let stepOneData;
    let stepTwoData;

    try {
      const parsedContent = sessionStorage.getItem(
        RESERVATION_STEP_ONE_DATA_KEY
      );

      if (parsedContent != null) {
        stepOneData = JSON.parse(parsedContent) as State;
        sessionStorage.setItem(
          RESERVATION_STEP_ZERO_DATA_KEY,
          JSON.stringify({
            selectedDate: stepOneData.selectedDate,
            selectionMode: SelectionMode.Date,
            selectedProcedures:
              stepOneData.detailedSelectedProcedures.map<SelectedProcedure>(
                ({ service, employee }) => {
                  return {
                    id: service.id,
                    workerId: employee.id,
                    categoryId: service.categoryId,
                  };
                }
              ),
          })
        );
      }
    } catch (error) {
      console.error(error);
    }

    try {
      const parsedContent = sessionStorage.getItem(
        RESERVATION_STEP_TWO_DATA_KEY
      );
      if (parsedContent != null) {
        stepTwoData = JSON.parse(parsedContent) as ContactsFormSummary;
      }
    } catch (error) {
      console.error(error);
    }

    return {
      stepOneData,
      stepTwoData,
    };
  }, []);

  if (state.stepOneData == null) {
    return <Navigate to="/rezervacija" replace />;
  }

  const { selectedDate, detailedSelectedProcedures, organizationId } =
    state.stepOneData;
  const startDate = new Date(selectedDate);
  const procedureLengthInMinutes = detailedSelectedProcedures.reduce(
    (minutes, procedure) => {
      return minutes + getMinutesFromLength(procedure.service.length);
    },
    0
  );
  const endDate = addMinutes(new Date(selectedDate), procedureLengthInMinutes);
  const humanReadableLength = getHumanReadableTimeTitle(
    procedureLengthInMinutes
  );

  return (
    <MainLayout>
      <h1>Susisiekime</h1>
      <div className={s.reservationContacts}>
        <form
          id="reservation-form"
          onChange={(event) => {
            const formData = new FormData(event.currentTarget);
            sessionStorage.setItem(
              RESERVATION_STEP_TWO_DATA_KEY,
              JSON.stringify(formDataToString(formData))
            );
          }}
          onSubmit={async (event) => {
            event.preventDefault();

            if (!state.stepOneData) {
              return;
            }

            const formData = new FormData(event.currentTarget);
            const contactsFormSummary = formDataToString(formData);

            try {
              const response = await Visit.Booking({
                organizationId,
                start: `${format(startDate, "yyyy-MM-dd")}T${format(
                  startDate,
                  "HH:mm"
                )}`,
                serviceWorkerMap: detailedSelectedProcedures.map(
                  ({ service, employee }) => {
                    return {
                      serviceId: service.id,
                      workerId:
                        employee.id !== ANY_WORKER_ID ? employee.id : undefined,
                    };
                  }
                ),
                client: {
                  name: contactsFormSummary.name,
                  phone: contactsFormSummary.phone,
                },
                skipClientSmsNotification: !contactsFormSummary.remindViaSMS,
                selfService: true,
              });
              sessionStorage.removeItem(RESERVATION_STEP_ZERO_DATA_KEY);
              sessionStorage.removeItem(RESERVATION_STEP_ONE_DATA_KEY);
              sessionStorage.removeItem(RESERVATION_STEP_TWO_DATA_KEY);
              const reservationSummary: ReservationSummary = {
                stepOneData: state.stepOneData,
                stepTwoData: contactsFormSummary,
                anyEmployeeSummary: response,
              };

              navigate("/rezervacija/sekminga", { state: reservationSummary });
            } catch (error) {
              if (error instanceof HTTPError && error.status === 409) {
                alert(
                  "Atsiprašome, bet pasirinktas laikas jau užimtas... Pasirinkite kitą laiką..."
                );

                navigate("/rezervacija");
                return;
              }

              alert("Atsiprašome... Įvyko klaida. Kviečiame bandyti vėliau.");
            }
          }}
        >
          <label>
            Pasimatysime
            <input
              type="text"
              value={`${format(startDate, "yyyy-MM-dd HH:mm")} - ${format(
                endDate,
                "HH:mm"
              )} (${humanReadableLength})`}
              readOnly
            />
          </label>
          <label>
            Vardas
            <input
              name={FormEntries.Name}
              type="text"
              autoFocus
              required
              minLength={2}
              defaultValue={state.stepTwoData?.name || ""}
            />
          </label>
          <label>
            Telefono nr.
            <input
              name={FormEntries.Phone}
              type="text"
              required
              defaultValue={state.stepTwoData?.phone || ""}
            />
          </label>
          <div className={s.label}>
            Priminti apie apsilankymą
            <div className={s.remindCheckboxes}>
              <label>
                <input
                  name={FormEntries.RemindViaSMS}
                  type="checkbox"
                  defaultChecked={
                    state.stepTwoData?.remindViaSMS === "on" || true
                  }
                />
                SMS žinute
              </label>
            </div>
          </div>
          <label>
            Sutikimas su sąlygomis
            <div className={s.agreementCheckbox}>
              <input
                name={FormEntries.AgreedWithConsent}
                type="checkbox"
                required
              />
              Sutinku, kad šioje formoje pateikti mano asmens duomenys užsakymo
              aptarnavimo tikslais būtų valdomi, tvarkomi ir perduoti paslaugos
              tiekėjui.
            </div>
          </label>
        </form>
        <aside>
          {detailedSelectedProcedures.map(
            ({ service, employee, price }, index, self) => {
              const lengthUntilProcedureMinutes = self
                .slice(0, index)
                .reduce((minutes, { service }) => {
                  return minutes + getMinutesFromLength(service.length);
                }, 0);
              const start = addMinutes(
                new Date(selectedDate),
                lengthUntilProcedureMinutes
              );
              const end = addMinutes(
                new Date(start),
                getMinutesFromLength(service.length)
              );

              return (
                <section key={service.id}>
                  <header>{`Procedūra #${index + 1}`}</header>
                  <ul>
                    <li>
                      Pavadinimas:
                      <span> {service.name}</span>
                    </li>
                    <li>
                      Planuojamas laikas:
                      <span>
                        {" "}
                        {`${format(start, "HH:mm")} - ${format(end, "HH:mm")}`}
                      </span>
                    </li>
                    <li>
                      Trukmė:
                      <span> {getHumanReadableTimeTitle(service.length)}</span>
                    </li>
                    <li>
                      Specialistas:
                      <span> {employee.displayName}</span>
                    </li>
                    <li>
                      Kaina:
                      <span> {`${getPriceString(price)} ${CURRENCY}`}</span>
                    </li>
                  </ul>
                </section>
              );
            }
          )}
        </aside>
      </div>
      <div className={s.formControls}>
        <Link
          to="/rezervacija"
          className={classNames("button", s.textualButton)}
        >
          <svg
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 28"
            width="16"
            height="28"
          >
            <path d="m16 1.8v24.4q0 0.7-0.5 1.3-0.5 0.5-1.3 0.5-0.7 0-1.2-0.5l-12.4-12.3q-0.6-0.5-0.6-1.2 0-0.7 0.6-1.2l12.4-12.3q0.5-0.5 1.2-0.5 0.8 0 1.3 0.5 0.5 0.6 0.5 1.3z" />
          </svg>
          Grįžti
        </Link>
        <button form="reservation-form">Patvirtinti</button>
      </div>
    </MainLayout>
  );
};
