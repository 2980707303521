import { API_BASE_URL } from "./api-config";

interface Options {
  signal?: AbortSignal;
}

export interface TimeRange {
  start: string;
  end: string;
}

export interface ServiceAvailability {
  serviceId: string;
  /**
   * Send no worker id when all employes selected.
   */
  workerId?: string;
}

interface VisitAvailabiliyRequest {
  serviceWorkerMap: ServiceAvailability[];
  organizationId: string;
  selfService: boolean;
}

interface DayAvailability {
  date: string;
  slots: TimeRange[];
}

interface VisitAvailabilityResponse {
  daysAvailabilities: DayAvailability[];
}

interface VisitBookingRequest {
  organizationId: string;
  start: string;
  serviceWorkerMap: ServiceAvailability[];
  client: {
    name: string;
    phone: string;
  };
  skipClientSmsNotification: boolean;
  selfService: boolean;
}

export interface VisitBookingResponse {
  service1VisitId: string;
  service1WorkerName: string;
  service2VisitId: string | null;
  service2WorkerName: string | null;
}

const BASE_URL = `${API_BASE_URL}/api/Visit`;

export class HTTPError<TBody = unknown> extends Error {
  constructor(summary: string, public status: number, public body: TBody) {
    super(summary);
  }
}

export const CONFLICT_STATUS = 409;

export const Visit = {
  Availability: async (
    options: VisitAvailabiliyRequest,
    requestOptions: Options
  ): Promise<VisitAvailabilityResponse> => {
    const response = await fetch(`${BASE_URL}/Availability`, {
      method: "POST",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
      },
      ...requestOptions,
    });

    if (response.status === 204) {
      return {
        daysAvailabilities: [],
      };
    }

    return response.json();
  },
  Booking: async (
    options: VisitBookingRequest
  ): Promise<VisitBookingResponse> => {
    const response = await fetch(`${BASE_URL}/Booking`, {
      method: "POST",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new HTTPError(
        `HTTP error ${response.status}`,
        response.status,
        response.json()
      );
    }
  },
};
