import { Employee, Service } from "../api/Organization";
import { VisitBookingResponse } from "../api/Visit";
import {
  EmployeePrice,
  SelectedProcedure,
} from "../pages/reservation/ReservationContracts";

export const RESERVATION_STEP_ZERO_DATA_KEY = "reservation-step-zero";
export const RESERVATION_STEP_ONE_DATA_KEY = "reservation-step-one";
export const RESERVATION_STEP_TWO_DATA_KEY = "reservation-step-two";

export interface DetailedSelectedProcedure {
  selectedProcedure: SelectedProcedure;
  service: Omit<Service, "workerIdList">;
  employee: Employee;
  price: EmployeePrice;
}

export interface ReservationStepOneData {
  organizationId: string;
  selectedDate: string;
  detailedSelectedProcedures: DetailedSelectedProcedure[];
}

export interface ContactsFormPage {
  name: string;
  phone: string;
  remindViaSMS: string;
  agreedWithConsent: string;
}

export interface ReservationSummary {
  stepOneData: ReservationStepOneData;
  stepTwoData: ContactsFormPage;
  anyEmployeeSummary: VisitBookingResponse;
}
