import {
  useEffect,
  useState,
  useReducer,
  Reducer,
  useLayoutEffect,
} from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { useNavigate } from "react-router-dom";
import addMinutes from "date-fns/addMinutes";
import setMilliseconds from "date-fns/setMilliseconds";
import setMinutes from "date-fns/setMinutes";
import setSeconds from "date-fns/setSeconds";
import setHours from "date-fns/setHours";
import lt from "date-fns/locale/lt";
import { Organization, OrganizationInfo } from "../../api/Organization";
import { ServiceAvailability, Visit } from "../../api/Visit";
import { Loader } from "../../components/loader";
import { MainLayout } from "../../components/main-layout";
import { ServiceEmployee } from "../../components/service-employee/ServiceEmployee";
import { ProcedurePicker } from "../../components/procedure-picker/ProcedurePicker";
import {
  ServicesByCategory,
  ModifiedService,
  ANY_WORKER_ID,
  WorkersById,
  SelectedProcedure,
} from "./ReservationContracts";
import {
  getHumanReadableTimeTitle,
  getPriceString,
} from "./ReservationHelpers";
import { ReactComponent as CrossSVG } from "../../svgs/cross.svg";
import { useIsMounted } from "../../hooks/useIsMounted";
import {
  DetailedSelectedProcedure,
  ReservationStepOneData,
  RESERVATION_STEP_ONE_DATA_KEY,
  RESERVATION_STEP_ZERO_DATA_KEY,
} from "../../contracts/reservation-contacts-contracts";
import { ORGANIZATION_ID } from "../../api/api-config";

import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker.scss";
import s from "./ReservationPage.module.scss";

registerLocale("lt", lt);
setDefaultLocale("lt");

enum Status {
  Pending = "pending",
  Loaded = "loaded",
  Error = "error",
}

export enum SelectionMode {
  Procedure = "procedure",
  Date = "date",
}

type RequestDto<TData> =
  | {
      value: TData;
      status: Status.Loaded;
    }
  | { value: undefined; status: Status.Pending }
  | { value: undefined; status: Status.Error };

interface State {
  selectedProcedures: SelectedProcedure[];
  selectedDate: Date | null;
  selectionMode: SelectionMode;
  availabilityResponse: RequestDto<{
    availableTimesByDate: Record<string, Date[]>;
    availableDates: Date[];
  }>;
}

enum ReservationActionType {
  ProcedureSelected = "procedure-selected",
  SelectAnotherProcedure = "select-another-procedure",
  CancelAnotherProcedureSelection = "cancel-another-procedure-selection",
  DateSelected = "date-selected",
  RemoveSelectedProcedure = "remove-selected-procedure",
  AvailabilityResponseSet = "availability-response-set",
}

interface ProcedureSelectedAction {
  type: ReservationActionType.ProcedureSelected;
  selectedProcedure: SelectedProcedure;
}

interface SelectAnotherProcedureAction {
  type: ReservationActionType.SelectAnotherProcedure;
}

interface CancelAnotherProcedureSelectionAction {
  type: ReservationActionType.CancelAnotherProcedureSelection;
}

interface DateSelectedAction {
  type: ReservationActionType.DateSelected;
  date: Date;
}

interface RemoveSelectedProcedure {
  type: ReservationActionType.RemoveSelectedProcedure;
  id: string;
}

interface AvailabilityResponseSetAction {
  type: ReservationActionType.AvailabilityResponseSet;
  response: RequestDto<{
    availableTimesByDate: Record<string, Date[]>;
    availableDates: Date[];
  }>;
}

type ReservationAction =
  | ProcedureSelectedAction
  | SelectAnotherProcedureAction
  | CancelAnotherProcedureSelectionAction
  | DateSelectedAction
  | RemoveSelectedProcedure
  | AvailabilityResponseSetAction;

export const ReservationPage = () => {
  const isMounted = useIsMounted();
  const [organizationInfoFetchId, setOrganizationInfoFetchId] = useState(0);
  const [availabilityFetchId, setAvailabilityFetchId] = useState(0);
  const [organizationInfoResponse, setOrganizationInfoResponse] = useState<
    RequestDto<{
      organizationInfo: OrganizationInfo;
      servicesByCategory: ServicesByCategory;
      workersById: WorkersById;
    }>
  >({
    value: undefined,
    status: Status.Pending,
  });
  const navigate = useNavigate();

  const [
    { selectedDate, selectionMode, selectedProcedures, availabilityResponse },
    dispatch,
  ] = useReducer<Reducer<State, ReservationAction>, State>(
    (state, action): State => {
      switch (action.type) {
        case ReservationActionType.ProcedureSelected: {
          return {
            ...state,
            selectedProcedures: [
              ...state.selectedProcedures,
              action.selectedProcedure,
            ],
            selectionMode: SelectionMode.Date,
          };
        }
        case ReservationActionType.RemoveSelectedProcedure: {
          const filteredProcedures = state.selectedProcedures.filter(
            (item) => item.id !== action.id
          );
          return {
            ...state,
            selectedProcedures: filteredProcedures,
            selectedDate: filteredProcedures.length ? state.selectedDate : null,
            selectionMode: filteredProcedures.length
              ? state.selectionMode
              : SelectionMode.Procedure,
          };
        }
        case ReservationActionType.SelectAnotherProcedure: {
          return {
            ...state,
            selectionMode: SelectionMode.Procedure,
          };
        }
        case ReservationActionType.CancelAnotherProcedureSelection: {
          return {
            ...state,
            selectionMode: SelectionMode.Date,
          };
        }
        case ReservationActionType.DateSelected: {
          const baseDay = setHours(new Date(action.date), 0);
          const targetDay = setMilliseconds(
            setSeconds(
              setMinutes(
                addMinutes(baseDay, -1 * baseDay.getTimezoneOffset()),
                0
              ),
              0
            ),
            0
          ).toISOString();

          const daysAvailabilities =
            state.availabilityResponse.value?.availableTimesByDate[targetDay];
          const newDate = daysAvailabilities?.find(
            (ongoingDate) =>
              ongoingDate.toISOString() === new Date(action.date).toISOString()
          );

          return {
            ...state,
            selectedDate: newDate
              ? new Date(newDate)
              : daysAvailabilities?.[0] || null,
          };
        }
        case ReservationActionType.AvailabilityResponseSet: {
          const { response } = action;

          return {
            ...state,
            selectedDate:
              state.selectedDate ??
              (action.response.value?.availableTimesByDate[
                action.response.value?.availableDates[0]?.toISOString()
              ]?.[0] ||
                new Date()),
            availabilityResponse: response,
          };
        }
        default: {
          return state;
        }
      }
    },
    {
      selectedProcedures: [],
      selectedDate: null,
      selectionMode: SelectionMode.Procedure,
      availabilityResponse: {
        value: undefined,
        status: Status.Pending,
      },
    },
    () => {
      try {
        const reservationStepOneStringData = sessionStorage.getItem(
          RESERVATION_STEP_ZERO_DATA_KEY
        );
        sessionStorage.removeItem(RESERVATION_STEP_ZERO_DATA_KEY);
        if (reservationStepOneStringData) {
          const initialState = JSON.parse(reservationStepOneStringData);

          if (initialState) {
            return {
              selectedProcedures: initialState.selectedProcedures,
              selectedDate: new Date(initialState.selectedDate),
              selectionMode: initialState.selectionMode,
              availabilityResponse: {
                value: undefined,
                status: Status.Pending,
              },
            };
          }
        }
      } catch {
        // Do nothing.
      }

      return {
        selectedProcedures: [],
        selectedDate: null,
        selectionMode: SelectionMode.Procedure,
        availabilityResponse: {
          value: undefined,
          status: Status.Pending,
        },
      };
    }
  );

  useLayoutEffect(() => {
    const selectedTimeElement = document.querySelector<HTMLLIElement>(
      ".react-datepicker__time-list-item.react-datepicker__time-list-item--selected"
    );

    if (!selectedTimeElement) {
      return;
    }

    const timeContainer = document.querySelector<HTMLUListElement>(
      ".react-datepicker__time-list"
    );

    if (!timeContainer) {
      return;
    }

    const isSelectedTimeElementVisible =
      selectedTimeElement.offsetTop > timeContainer.scrollTop &&
      selectedTimeElement.offsetTop + selectedTimeElement.offsetHeight <
        timeContainer.scrollTop + timeContainer.offsetHeight;

    if (!isSelectedTimeElementVisible) {
      timeContainer.scrollTo(
        0,
        selectedTimeElement.offsetTop -
          (timeContainer.offsetHeight / 2 -
            selectedTimeElement.offsetHeight / 2)
      );
    }
  }, [selectedDate]);

  useEffect(() => {
    const abortController = new AbortController();
    const loadData = async () => {
      setOrganizationInfoResponse({
        value: undefined,
        status: Status.Pending,
      });

      try {
        const organizationInfo = await Organization.OrganizationInfo({
          signal: abortController.signal,
        });
        if (!isMounted()) {
          return;
        }
        const servicesByCategory =
          organizationInfo.serviceList.reduce<ServicesByCategory>(
            (serviceByCategory, service, index) => {
              const prices = service.workerIdList.map(
                (serviceEmployee) => serviceEmployee.price
              );
              const min = Math.min(...prices);
              const max = Math.max(...prices);
              const modifiedService: ModifiedService = {
                ...service,
                workerIdList:
                  service.workerIdList.length > 1
                    ? [
                        {
                          workerId: ANY_WORKER_ID,
                          price: min === max ? min : { min, max },
                        },
                        ...service.workerIdList,
                      ]
                    : service.workerIdList,
              };

              if (!serviceByCategory[service.categoryId]) {
                serviceByCategory[service.categoryId] = [modifiedService];
              } else {
                serviceByCategory[service.categoryId].push(modifiedService);
              }
              return serviceByCategory;
            },
            {}
          );
        const workersById = organizationInfo.workerList.reduce<WorkersById>(
          (workersById, worker) => {
            workersById[worker.id] = worker;
            return workersById;
          },
          {}
        );
        workersById[ANY_WORKER_ID] = {
          id: ANY_WORKER_ID,
          displayName: "Bet kuris specialistas",
          description: "Specialistas bus parinktas automatiškai",
        };
        setOrganizationInfoResponse({
          value: {
            organizationInfo,
            servicesByCategory,
            workersById,
          },
          status: Status.Loaded,
        });
      } catch (error) {
        if (
          error instanceof DOMException &&
          (error.name === "AbortError" || error.code === 20 || !isMounted())
        ) {
          return;
        }

        console.error(error);
        setOrganizationInfoResponse({
          value: undefined,
          status: Status.Error,
        });
      }
    };
    loadData();

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationInfoFetchId]);

  useEffect(() => {
    if (selectionMode === SelectionMode.Procedure) {
      return;
    }

    const abortController = new AbortController();
    const loadData = async () => {
      try {
        const response = await Visit.Availability(
          {
            organizationId: ORGANIZATION_ID,
            serviceWorkerMap: selectedProcedures.map<ServiceAvailability>(
              ({ id, workerId }) => ({
                workerId: workerId === ANY_WORKER_ID ? undefined : workerId,
                serviceId: id,
              })
            ),
            selfService: true,
          },
          {
            signal: abortController.signal,
          }
        );
        if (!isMounted()) {
          return;
        }

        const availabilities = response.daysAvailabilities.reduce<{
          availableDates: Date[];
          availableTimesByDate: Record<string, Date[]>;
        }>(
          ({ availableDates, availableTimesByDate }, { date, slots }) => {
            const ongoingDate = new Date(date);
            availableDates.push(ongoingDate);

            const availableTimes = slots.reduce<Date[]>(
              (times, { start, end }) => {
                const startDate = new Date(`${date}T${start}`);
                const endDate = new Date(`${date}T${end}`);

                let i = new Date(startDate);
                do {
                  times.push(i);
                  i = addMinutes(i, 15);
                } while (i <= endDate);
                return times;
              },
              []
            );
            availableTimesByDate[ongoingDate.toISOString()] = availableTimes;
            return { availableDates, availableTimesByDate };
          },
          {
            availableDates: [],
            availableTimesByDate: {},
          }
        );
        dispatch({
          type: ReservationActionType.AvailabilityResponseSet,
          response: {
            status: Status.Loaded,
            value: availabilities,
          },
        });
      } catch (error) {
        console.error(error);
        if (!isMounted()) {
          return;
        }
        dispatch({
          type: ReservationActionType.AvailabilityResponseSet,
          response: {
            status: Status.Error,
            value: undefined,
          },
        });
      }
    };
    loadData();

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionMode, selectedProcedures, availabilityFetchId]);

  if (organizationInfoResponse.status === Status.Error) {
    return (
      <MainLayout>
        <h1>Suplanuokite apsilankymą</h1>
        <section className={s.errorSection}>
          <h1>Ups... :(</h1>
          <div className={s.errorText}>Atsiprašome, įvyko klaida.</div>
          <span
            className={s.link}
            onClick={() =>
              setOrganizationInfoFetchId(organizationInfoFetchId + 1)
            }
          >
            Pabandykim dar kartą?
          </span>
        </section>
      </MainLayout>
    );
  }

  if (organizationInfoResponse.status === Status.Pending) {
    return (
      <MainLayout>
        <h1>Suplanuokite apsilankymą</h1>
        <Loader className={s.categoriesLoader} />
      </MainLayout>
    );
  }

  // TODO: Upgrade react-scripts.
  // TODO: Add animation for accordeon.
  // TODO: Route leave hook.
  // TODO: Add validation between local storage and server services. If something not found - reject all the local storage content. Hash the request maybe?
  // TODO: Captcha.

  const includeDates = availabilityResponse.value?.availableDates || [];
  const includeTimes = selectedDate
    ? availabilityResponse.value?.availableTimesByDate[
        new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          (-1 * selectedDate.getTimezoneOffset()) / 60,
          0,
          0
        ).toISOString()
      ]
    : [];

  return (
    <MainLayout>
      <h1>Suplanuokite apsilankymą</h1>
      {selectedProcedures.length ? (
        <div className={s.selectedServicesContainer}>
          {selectedProcedures.map(({ id, categoryId, workerId }, index) => {
            const service = organizationInfoResponse.value.servicesByCategory[
              categoryId
            ].find((modifiedService) => modifiedService.id === id);
            const worker = organizationInfoResponse.value.workersById[workerId];

            if (!service) {
              return null;
            }

            const price = service.workerIdList.find(
              ({ workerId: id }) => id === workerId
            )?.price;
            const category =
              organizationInfoResponse.value.organizationInfo.categoryList.find(
                ({ id }) => id === service.categoryId
              );

            return (
              <div className={s.selectedProcedure} key={id}>
                <div className={s.selectedProcedureDetails}>
                  <div className={s.topHeading}>
                    <span className={s.procedureCount}>{`Procedūra #${
                      index + 1
                    }`}</span>
                    <span className={s.length}>
                      Trukmė: {getHumanReadableTimeTitle(service.length)}
                    </span>
                  </div>
                  <div className={s.titles}>
                    <span>{service.name}</span>
                    <span className={s.category}>{category?.name || "-"}</span>
                  </div>
                  <div className={s.worker}>
                    <div className={s.sectionTitle}>Specialistas</div>
                    <ServiceEmployee
                      displayName={worker.displayName}
                      description={worker.description}
                      price={price ? getPriceString(price) : ""}
                      workerId={workerId}
                    />
                  </div>
                </div>
                <div
                  className={s.removeSelectedProcedure}
                  onClick={() => {
                    dispatch({
                      id,
                      type: ReservationActionType.RemoveSelectedProcedure,
                    });
                  }}
                >
                  <CrossSVG />
                </div>
              </div>
            );
          })}
          {selectedProcedures.length >= 2 ||
          selectionMode === SelectionMode.Procedure ? null : (
            <div
              className={s.addProcedure}
              onClick={() => {
                dispatch({
                  type: ReservationActionType.SelectAnotherProcedure,
                });
              }}
            >
              <svg
                version="1.2"
                baseProfile="tiny-ps"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 15 15"
                width="15"
                height="15"
              >
                <path d="M14.99 6.48L14.99 8.53C14.99 8.81 14.9 9.05 14.7 9.25C14.5 9.45 14.26 9.55 13.97 9.55L9.54 9.55L9.54 13.98C9.54 14.26 9.44 14.51 9.24 14.7C9.05 14.9 8.8 15 8.52 15L6.48 15C6.19 15 5.95 14.9 5.75 14.7C5.55 14.51 5.45 14.26 5.45 13.98L5.45 9.55L1.02 9.55C0.74 9.55 0.5 9.45 0.3 9.25C0.1 9.05 0 8.81 0 8.53L0 6.48C0 6.2 0.1 5.96 0.3 5.76C0.5 5.56 0.74 5.46 1.02 5.46L5.45 5.46L5.45 1.03C5.45 0.75 5.55 0.51 5.75 0.31C5.95 0.11 6.19 0.01 6.48 0.01L8.52 0.01C8.8 0.01 9.05 0.11 9.24 0.31C9.44 0.51 9.54 0.75 9.54 1.03L9.54 5.46L13.97 5.46C14.26 5.46 14.5 5.56 14.7 5.76C14.9 5.96 14.99 6.2 14.99 6.48Z" />
              </svg>
              <span className={s.addProcedureButtonTitle}>
                Pridėti procedūrą
              </span>
            </div>
          )}
        </div>
      ) : null}
      {selectionMode === SelectionMode.Procedure ? (
        <>
          {selectedProcedures.length ? (
            <div className={s.addProcedureTitle}>
              <h2>Pridėti procedūrą</h2>
              <div
                className={s.cancelAddProcedure}
                onClick={() => {
                  dispatch({
                    type: ReservationActionType.CancelAnotherProcedureSelection,
                  });
                }}
              >
                <CrossSVG />
              </div>
            </div>
          ) : null}
          <ProcedurePicker
            servicesByCategory={
              organizationInfoResponse.value.servicesByCategory
            }
            categoryList={
              organizationInfoResponse.value.organizationInfo.categoryList
            }
            workersById={organizationInfoResponse.value.workersById}
            selectedProcedureIds={selectedProcedures.map((x) => x.id)}
            onProcedureSelected={(selectedProcedure) => {
              dispatch({
                type: ReservationActionType.ProcedureSelected,
                selectedProcedure,
              });

              setTimeout(() => {
                document.getElementById("visit-time")?.scrollIntoView();
              });
            }}
          />
        </>
      ) : (
        <>
          <h2 id="visit-time">Vizito laikas</h2>
          <div className={s.dateTimeContainer}>
            {(() => {
              if (availabilityResponse.status === Status.Pending) {
                return <Loader />;
              }

              if (availabilityResponse.status === Status.Error) {
                return (
                  <section className={s.errorSection}>
                    <h1>Ups... :(</h1>
                    <div className={s.errorText}>
                      Atsiprašome, įvyko klaida.
                    </div>
                    <span
                      className={s.link}
                      onClick={() =>
                        setAvailabilityFetchId(availabilityFetchId + 1)
                      }
                    >
                      Pabandykim dar kartą?
                    </span>
                  </section>
                );
              }

              if (!includeDates.length) {
                return (
                  <section className={s.errorSection}>
                    <h1>O ne... ;(</h1>
                    <p>Šioms procedūroms neturime laisvų laikų...</p>
                    <p>Pabandykite pasirinkti kitą derinį...</p>
                  </section>
                );
              }

              return (
                <DatePicker
                  inline
                  showTimeSelect
                  timeCaption="laikas"
                  className={s.dateTime}
                  selected={selectedDate}
                  onChange={(date: Date) => {
                    dispatch({
                      date,
                      type: ReservationActionType.DateSelected,
                    });
                  }}
                  includeDates={includeDates}
                  includeTimes={includeTimes}
                  timeIntervals={15}
                />
              );
            })()}
          </div>
          <div className={s.continueControlContainer}>
            <button
              disabled={
                availabilityResponse.status !== Status.Loaded ||
                !includeDates.length
              }
              onClick={() => {
                if (selectedDate == null) {
                  alert(
                    "Nepavyko rasti parinktos datos. Pabandykite pakeisti datą."
                  );
                  return;
                }

                try {
                  const detailedSelectedProcedures: DetailedSelectedProcedure[] =
                    selectedProcedures.map((selectedProcedure) => {
                      const { categoryId, id, workerId } = selectedProcedure;
                      const employee =
                        organizationInfoResponse.value.workersById[workerId];
                      const service =
                        organizationInfoResponse.value.servicesByCategory[
                          categoryId
                        ].find((modifiedService) => modifiedService.id === id);

                      if (!service) {
                        // TODO: Handle with care.
                        throw new Error("Could not find selected service...");
                      }
                      const price = service.workerIdList.find(
                        ({ workerId: id }) => id === workerId
                      )?.price;

                      if (!price) {
                        // TODO: Handle with care.
                        throw new Error(
                          "Could not find price for selected service..."
                        );
                      }

                      const { workerIdList, ...restOfService } = service;

                      return {
                        selectedProcedure,
                        employee,
                        service: restOfService,
                        price,
                      };
                    });

                  const reservationStepOneDate: ReservationStepOneData = {
                    detailedSelectedProcedures,
                    organizationId:
                      organizationInfoResponse.value.organizationInfo.id,
                    selectedDate: selectedDate.toISOString(),
                  };

                  sessionStorage.setItem(
                    RESERVATION_STEP_ONE_DATA_KEY,
                    JSON.stringify(reservationStepOneDate)
                  );
                  navigate({ pathname: "/rezervacija/kontaktai" });
                } catch (error) {
                  console.error(error);
                  alert(
                    "Nepavyko rasti paslaugos ar paslaugos kainos. Prašome bandyti dar kartą vėliau."
                  );
                }
              }}
            >
              Tęsti
            </button>
          </div>
        </>
      )}
    </MainLayout>
  );
};
