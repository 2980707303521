import { MouseEventHandler, ReactNode, useRef, useState } from "react";

interface Props {
  header: (
    isOpen: boolean,
    onClick: MouseEventHandler<HTMLElement>
  ) => ReactNode;
  children?: ReactNode;
  className?: string;
}

export const Dropdown = ({ header, children, className }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLElement | null>(null);
  return (
    <article className={className} ref={dropdownRef}>
      {header(isOpen, () => {
        setIsOpen((value) => !value);
        setTimeout(() => {
          dropdownRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        });
      })}
      {isOpen ? children : null}
    </article>
  );
};
